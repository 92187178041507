"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'Utoniq',
    organizationId: 'W2Ubo9vhm7ouvXSv9W9p',
    version: '3.0',
    ionicAppId: 'a60f53e0',
    applicationKey: 'utoniq',
    applicationType: 'organization',
    artistId: undefined,
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.utoniq',
        appId: '1448888446',
    },
    android: {
        bundleId: 'com.utoniq',
    },
    platform: ['web', 'native'],
    custom: {
        modules: {
            tutorial: true,
            trade: false,
            membership: true,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: true,
            nft: true,
            liveStream: true,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: ['home', 'notifications', 'artist', 'myProfile'],
        artistTabs: ['home', 'posts', 'threads'],
    },
    deeplinkUrl: 'https://app.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/2299305',
    applink: 'utoniq.com',
    storagePath: 'utoniq',
    /*
     * utoniqアプリでは、アプリが複数インストールされているときにDynamic Linkで別アプリが開いてしまうため、utoniqappプロジェクトを作成して変更。
     * https://app.clickup.com/t/908127/UT-9974
     */
    dynamicLinkPrefix: 'utoniqapp',
    deeplinkStg: 'utoniq-stg.netlify.app',
    deeplinkProd: 'app.utoniq.com',
};
exports.default = appConfig;
